import ApiServices from "../service";

export const provider = {
  getProvider: (id, accessToken) =>
    ApiServices.getData(`/api/provider/${id}/`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getProviderClientStatusByCaseId: (caseId, accessToken) =>
    ApiServices.getData(`/api/pro_cli_status/?case=${caseId}`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  getProviderClientStatusByClientId: (clientId, accessToken) =>
    ApiServices.getData(`/api/pro_cli_status/?client=${clientId}`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err),

  getAvailableServices: (
    id,
    accessToken,
    searchValue = null,
    pagination = null
  ) => {
    let search = searchValue ? `search=${searchValue}` : "";
    let paginate = pagination ? `${pagination}` : "";

    let query = search || paginate ? "?" : "";
    let deliminator = search && search !== "" && paginate ? "&" : "";

    return ApiServices.getData(
      `api/provider/${id}/available_services/${query}${search}${deliminator}${paginate}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },
  getCurrentServices: (id, accessToken, limit) => {
    return ApiServices.getData(
      `/api/provider/${id}/services/?limit=${limit}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },
  postCurrentServices: (data, id, accessToken) =>
    ApiServices.postData(`/api/provider/${id}/add_service/`, data, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  removeCurrentServices: (data, id, accessToken) =>
    ApiServices.postData(
      `/api/provider/${id}/remove_service/`,
      data,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getProviderTaggedEntities: (accessToken) =>
    ApiServices.getData("/api/entities/?entity_type=3", accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getProviderRelations: (providerId, accessToken, pagination = null) => {
    let provider = providerId ? `provider=${providerId}` : "";
    let paginate = pagination ? `${pagination}` : "";

    let query = provider || paginate ? "?" : "";
    let deliminator = provider && provider !== "" && paginate ? "&" : "";
    return ApiServices.getData(
      `/api/provider_relations/${query}${provider}${deliminator}${paginate}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err));
  },
  postProviderRelations: (data, accessToken) =>
    ApiServices.postData(`/api/provider_relations/`, data, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  deleteProviderRelations: (id, accessToken) =>
    ApiServices.deleteData(`/api/provider_relations/${id}/`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getManagedProviders: (accessToken) =>
    ApiServices.getData("/api/provider/managed/", accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getProvidersOnCase: (caseId, accessToken) =>
    ApiServices.getData(`/api/provider/?case=${caseId}`, accessToken)
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getManagedProviders1: (caseManagerPk, accessToken) =>
    ApiServices.getData(
      `/api/provider/?managed_by=${caseManagerPk}&simplified=true?`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),
  getProvidersOnCase1: (caseManagerPk, accessToken) =>
    ApiServices.getData(
      `/api/user_provider/?user=${caseManagerPk}&simplified=true?`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => console.log(err)),

  getProviderStatusHistory: (accessToken, provider, column) =>
    ApiServices.getData(
      `/api/provider_status_histories?provider=${provider}&column_name=${column}&ordering=-updated_at&limit=1`,
      accessToken
    )
      .then((res) => res)
      .catch((err) => console.log(err)),
};
