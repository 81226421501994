import {
  Box,
  Select,
  TextField,
  IconButton,
  Chip,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useContext, useEffect, useState } from "react";
import PlacesResponseListV2 from "../../../../global/PlaceResponseListV2";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { MAX_TYPE_PER_PROVIDER } from "../constant";
import DataContext from "../../../../../context/DataContext";
import ResponseModal from "../../../../global/ResponseModal";
import axios from "../../../../api/axios";
import PropTypes from "prop-types";

const defaultFormData = {
  name: "",
  provider_types: [],
  address: {
    raw: "",
    latitude: 0,
    longitude: 0,
  },
  phone: "",
  in_network: true,
};

const SelectValues = ({ selected, setFormData }) => {
  const handleDelete = (value) => {
    setFormData((prev) => {
      return {
        ...prev,
        provider_types: prev.provider_types.filter((opt) => opt !== value),
      };
    });
  };

  return (
    <Box className="flex flex-wrap gap-1">
      {selected.map((value) => (
        <Chip
          className="cursor-default"
          key={value}
          label={value}
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => handleDelete(value)}
        />
      ))}
    </Box>
  );
};

SelectValues.propTypes = {
  selected: PropTypes.array,
  setFormData: PropTypes.func,
};

const AddProviderForm = ({ onClose, setNewProviderCreated }) => {
  const { accessToken, loggedInUser, userRoles } = useContext(DataContext);

  const [formData, setFormData] = useState(defaultFormData);
  const [showErrors, setShowErrors] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [provTypeOptions, setProvTypeOptions] = useState([]);

  const canViewProviderType = userRoles.permissions.includes(
    "provider.view_providertype"
  );

  useEffect(() => {
    if (!canViewProviderType) {
      return setProvTypeOptions([]);
    }
    axios
      .get(`/api/provider_types/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        setProvTypeOptions(response.data.results ?? []);
      });
  }, [setProvTypeOptions, accessToken, canViewProviderType]);

  const handlePlaceSelect = (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((coordinate) => {
        setFormData({
          ...formData,
          address: {
            ...formData.address,
            latitude: coordinate.lat,
            longitude: coordinate.lng,
            raw: value,
          },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasError =
      !formData.name ||
      !formData.provider_types.length ||
      !formData.address.raw ||
      !formData.phone;

    if (hasError) {
      setShowErrors(true);
      return;
    }

    axios
      .post(
        "/api/provider/",
        {
          name: formData.name?.trim(),
          provider_types: formData.provider_types.map(
            (name) => provTypeOptions.find((option) => option.name === name).id
          ),
          address: formData.address,
          phone: formData.phone?.trim(),
          in_network: true,
          managed_by: loggedInUser?.pk,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${accessToken}`,
          },
        }
      )
      .then(() => {
        setNewProviderCreated(true);
        setOpenSuccess(true);
      })
      .catch((error) => {
        if (error.response) {
          const errors = error.response.data;
          let arrayVals = [];
          if (errors.name) {
            arrayVals.push(`provider: ${errors.name}`);
          }
          if (errors.phone) {
            arrayVals.push(`phone: ${errors.phone}`);
          }
          if (errors.address) {
            for (const [key, value] of Object.entries(errors.address)) {
              arrayVals.push(`${key}: ${value}`);
            }
          }
          setErrorMessage(arrayVals);
        }
        setOpenError(true);
      });
  };

  const handleCloseForm = () => {
    onClose();
    setFormData(defaultFormData);
    setShowErrors(false);
    setOpenSuccess(false);
    setOpenError(false);
  };

  return (
    <div className="flex flex-col p-10">
      <div className="flex items-center">
        <Typography className="w-full text-2xl text-center text-purple-600 uppercase font-bold">
          ADD PROVIDER
        </Typography>
        <IconButton
          className="ml-auto w-fit"
          onClick={() => {
            handleCloseForm();
            setNewProviderCreated(false);
          }}
        >
          <Close />
        </IconButton>
      </div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <form
            className="flex flex-col gap-10 mt-10"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              label="Name"
              required
              className="w-full text-center"
              name="name"
              value={formData.name}
              variant="outlined"
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              error={showErrors && !formData.name}
              helperText={
                showErrors && !formData.name
                  ? "This field is required. Please enter provider name."
                  : ""
              }
            />

            <PlacesResponseListV2
              label="Address"
              address={formData.address.raw}
              setAddress={(e) => {
                setFormData({
                  ...formData,
                  address: {
                    ...formData.address,
                    raw: e,
                  },
                });
              }}
              handlePlaceSelect={handlePlaceSelect}
              inputRequired
              disableCustomStyles
              error={showErrors && !formData.address.raw}
              helperText={
                showErrors && !formData.address.raw
                  ? "This field is required. Please enter provider address."
                  : ""
              }
            />

            <TextField
              label="Phone"
              value={formData.phone}
              required
              error={showErrors && !formData.phone}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  phone: event.target.value,
                });
              }}
              helperText={
                showErrors && !formData.phone
                  ? "This field is required. Please enter provider phone."
                  : ""
              }
            />

            <FormControl
              fullWidth
              required
              error={showErrors && formData.provider_types.length === 0}
            >
              <InputLabel>Types</InputLabel>
              <Select
                label="Types"
                multiple
                value={formData.provider_types}
                onChange={(e) =>
                  setFormData({ ...formData, provider_types: e.target.value })
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                renderValue={(selected) => (
                  <SelectValues selected={selected} setFormData={setFormData} />
                )}
              >
                {provTypeOptions.length === 0 && (
                  <MenuItem disabled>No Results</MenuItem>
                )}
                {provTypeOptions.map((opt) => (
                  <MenuItem
                    key={opt.id}
                    value={opt.name}
                    disabled={
                      formData.provider_types.length >= MAX_TYPE_PER_PROVIDER &&
                      !formData.provider_types.includes(opt.name)
                    }
                  >
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
              {showErrors && formData.provider_types.length === 0 && (
                <FormHelperText error>
                  This field is required. Please enter provider types.
                </FormHelperText>
              )}
            </FormControl>
            <Button type="submit" variant="contained" className="w-fit mx-auto">
              Add Provider
            </Button>
          </form>
        </LocalizationProvider>
      </div>

      <ResponseModal
        title="New Provider Added"
        description={`You have successfully added "${formData.name}" to the
                system!`}
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleCloseForm}
      />
      <ResponseModal
        title="Error Adding Provider"
        isError
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default AddProviderForm;

AddProviderForm.propTypes = {
  onClose: PropTypes.func,
  setNewProviderCreated: PropTypes.func,
};
