import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  Dialog,
  TextField,
  IconButton,
  Box,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import {
  CloseOutlined,
  History,
  SupervisedUserCircle,
  CalendarMonth,
} from "@mui/icons-material";

import axios from "../../../../api/axios";
import qs from "query-string";
import DataContext from "../../../../../context/DataContext";
import ClientSearchMinimized from "../ClientSearchMinimized";
import CaseAssignment from "./CaseAssignment";
import ResponseModal from "../../../../global/ResponseModal";
import ClientProvidersv2 from "./ProvApptDetails/ClientProvidersv2";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import ActionItemsTab from "./ActionItemsTab";
import DocumentUpload from "./DocumentManager/DocumentUpload";
import ViewDocuments from "./DocumentManager/DocumentUpload/ViewDocuments";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import { formatDistance } from "date-fns";
import ClientNotes from "./ClientNotes";
import TabClientNotesCallLog from "./TabClientNotesCallLog";
import PermissionWrapper from "../../../../global/PermissionWrapper";
import ApiRequestErrorHandler from "../../../../global/ApiRequestErrorHandler";
import CaseSummaryV2 from "./CaseSummaryV2";
import CaseSummaryContext from "./CaseSummaryV2/context/CaseSummaryContext";
import Injuries from "./Injuries";
import ClientInfoV2 from "./ClientInfoV2";
import StaticBar from "./StaticBar";
import LiensBalancesModal from "../Settlement/LiensBalancesModal";
import TabPanel from "../../../../global/TabPanel";
import { a11yProps } from "../../../../global/helpers";
import { ENTITY_TYPE_PROVIDER } from "../../../../../globalConstants";
import Modal from "../../../../global/Modal";
import FormField from "../../../../global/FormComponents/field/FormField";
import { inputStyles } from "../constant";
import CaseFollowUps from "./FollowUps/CaseFollowUps/CaseFollowUps";
import ClientDetailProvider from "./context/ClientDetailContext";
import { provider } from "../../../../api/endpoints/provider";
import { SelectOptionsProCliStatusDataProcessorRole } from "../../../../global/FormComponents/SelectBasic/utils/SelectOptionsProCliStatusDataProcessorRole";

export default function ClientDetail() {
  const {
    caseManagerPk,
    accessToken,
    trigger,
    setTrigger,
    userRoles,
    userType,
    setSelectedClient,
    loggedInUser,
    tabTitle,
    setTabTitle,
    setLawFirmFollowUps,
    setClientFollowUps,
    setProviderFollowUps,
    setIsFollowUpsExpanded,
  } = useContext(DataContext);
  const { setCaseDetail, setClientInfoTab } = useContext(CaseSummaryContext);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [caseObj, setCaseObj] = useState();
  const [clientLawfirm, setClientLawfirm] = useState("");
  const [uploadDocument, setUploadDocument] = useState(false);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [clientDocs, setClientDocs] = useState("");
  const [viewImage, setViewImage] = useState(false);
  const [activeImage, setActiveImage] = useState("");
  const [openLfDetails, setOpenLfDetails] = useState(false);
  const [callback, setCallback] = useState(new Date());
  const [callBackReason, setCallBackReason] = useState("");
  const [docResLimit, setDocResLimit] = useState(10);
  const [showMoreDocsVisible, setShowMoreDocsVisible] = useState(false);
  const [openCbModal, setOpenCbModal] = useState(false);
  const [activeProvObj, setActiveProvObj] = useState({});
  const [documentTypeObj, setDocumentTypeObj] = useState();
  const [docTrigger, setDocTrigger] = useState(false);
  const [searchDocs, setSearchDocs] = useState("");
  const [docLoading, setDocLoading] = useState(false);
  const [docNeedsType, setDocNeedsType] = useState();
  const [openExpense, setOpenExpense] = useState(false);
  const [documentUploadProvOptions, setDocumentUploadProvOptions] = useState({});
  const clientInfoV2Ref = useRef();

  const navigate = useNavigate();
  const params = useParams();
  const isProvider =
    loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;

  const closeUploadModal = () => {
    setUploadDocument(false);
  };

  const closeImageModal = () => {
    setActiveImage("");
    setViewImage(false);
  };

  const handleApiError = (error) => {
    const res = error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setErrorArray(errArr);
    setOpenError(!openError);
  };

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  //**Main Case Query */
  useEffect(() => {
    setSelectedClient(params.id);

    if (!params) {
      return;
    } else {
      axios
        .get(`/api/case/${params.id}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const caseResponse = response.data;
          setCaseObj(caseResponse);
          setCaseDetail(caseResponse);
          setCallback(caseResponse.callback_time);
        })
        .catch((response) => {
          navigate("/dashboard");
          console.log(response);
        });
    }
    // eslint-disable-next-line
  }, [accessToken, trigger, params, userRoles.permissions, setSelectedClient]);

  useEffect(() => {
    axios
      .options(`/api/documents/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.actions.POST.document_type.choices;
        let obj = {};
        for (const item in data) {
          obj[data[item].value] = data[item].display_name;
        }

        setDocumentTypeObj(obj);
      })
      .catch((response) => {
        console.log(response);
      });
  }, [setDocumentTypeObj, accessToken]);

  useDebouncedEffect(
    () => {
      if (!params) {
        return;
      } else if (userRoles.permissions.includes("filemanager.view_document")) {
        setDocLoading(true);
        let query = qs.stringify(
          {
            search: !searchDocs ? null : searchDocs,
          },
          {
            skipNull: true,
          }
        );

        axios
          .get(
            `/api/documents/?limit=${docResLimit}&case=${params.id}&${query}&ordering=-created_at`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            if (response.data.count <= docResLimit) {
              setShowMoreDocsVisible(false);
            } else {
              setShowMoreDocsVisible(true);
            }
            setClientDocs(data);
            setDocLoading(false);
          })
          .catch((response) => {
            console.log(response);
          });
      } else {
        return;
      }
    },
    [
      caseObj,
      docResLimit,
      accessToken,
      docTrigger,
      params,
      userRoles.permissions,
      searchDocs,
    ],
    250
  );

  useEffect(() => {
    if (
      !params ||
      !userRoles.permissions.includes("filemanager.view_document") ||
      !caseObj
    ) {
      return;
    } else {
      axios
        .get(`/api/case/${caseObj.pk}/missing-docs/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data;
          setDocNeedsType(data);
        });
    }
  }, [
    accessToken,
    userRoles.permissions,
    caseObj,
    setDocNeedsType,
    docTrigger,
    params,
  ]);

  useEffect(() => {
    if (!userRoles.permissions.includes("filemanager.view_document")) return;

    // Get Images tab index and set active by default
    const idx = clientInfoV2Ref?.current?.getImagesTabIndex();
    if (idx !== -1) setClientInfoTab(idx);
  }, [userRoles?.permissions, setClientInfoTab]);

  const fetchManagedProviders = useCallback(async (fieldOptionsUrls) => {
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorArray(errArr);
        setOpenError(true);
      });
  }, []);

  useEffect(() => {
    const fieldOptionsUrls = [
      provider.getProviderClientStatusByCaseId(params.id, accessToken),
    ];

    fetchManagedProviders(fieldOptionsUrls)
      .then(async (results) => {
        if (results) {
          if (results[0]) {
            setActiveProvObj(results[0].results);
            setDocumentUploadProvOptions(
              SelectOptionsProCliStatusDataProcessorRole(
                results[0].results,
                isProvider,
                null
              )
            );
          }
        }
        return results;
      })
      .then(async (results) => {
        return results;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorArray(errArr);
        setOpenError(true);
      });
  }, [
    accessToken,
    fetchManagedProviders,
    params.id,
    userRoles.permissions,
    isProvider,
  ]);

  useEffect(() => {
    if (
      openLfDetails &&
      userRoles.permissions.includes("lawyer.view_all_law_firms") &&
      caseObj.law_firm
    ) {
      axios
        .get(`/api/lawfirm/${caseObj.law_firm}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data;
          setClientLawfirm(data);
        })
        .catch((response) => {
          console.log(response);
        });
    } else {
      return;
    }
  }, [openLfDetails, userRoles.permissions, caseObj, accessToken]);

  const addCallback = (type) => {
    let patchedData;
    if (type === "reset") {
      patchedData = {
        callback_time: null,
        callback_reason: null,
      };
    } else {
      patchedData = {
        callback_time: callback,
        callback_reason: callBackReason,
      };
    }

    axios
      .patch(`/api/case/${caseObj.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setTrigger(!trigger);
          setOpenCbModal(false);
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };
  //! This needs to stay at the very end of the list of functions
  const generateDetailTabs = () => {
    let detailComps = [];
    if (userRoles.permissions.includes("casemanager.view_case")) {
      detailComps.push({
        tabTitle: "Case Summary",
        order: 6,
        component: <CaseSummaryV2 caseObj={caseObj} />,
      });
    }
    if (
      userRoles.permissions.includes("filemanager.view_document") ||
      userRoles.permissions.includes("users.view_user") ||
      userRoles.permissions.includes("users.view_usercontactinfo")
    ) {
      detailComps.push({
        tabTitle: "Client Info",
        order: 1,
        component: (
          <ClientInfoV2
            ref={clientInfoV2Ref}
            caseObj={caseObj}
            setUploadDocument={setUploadDocument}
            docNeedsType={docNeedsType}
            setViewDocuments={setViewDocuments}
            docTrigger={docTrigger}
            setDocTrigger={setDocTrigger}
          />
        ),
      });
    }
    if (
      !isProvider &&
      userRoles.permissions.includes("casemanager.view_actionableitems")
    ) {
      detailComps.push({
        tabTitle: "Actionable Items",
        order: 2,
        component: <ActionItemsTab caseObj={caseObj} />,
      });
    }

    if (userRoles.permissions.includes("provider.view_providerclientstatus")) {
      detailComps.push({
        tabTitle: "Providers",
        order: 3,
        component: (
          <ClientProvidersv2
            isAppointments={false}
            caseInfo={caseObj}
            // providerAmountObj={providerAmountObj}
            activeProvObj={activeProvObj}
          />
        ),
      });
    }
    if (userRoles.permissions.includes("provider.view_appointment")) {
      detailComps.push({
        tabTitle: "Appointments",
        order: 4,
        component: (
          <ClientProvidersv2
            isAppointments={true}
            caseInfo={caseObj}
            activeProvObj={activeProvObj}
            setUploadDocument={setUploadDocument}
          />
        ),
      });
    }
    if (userRoles.permissions.includes("casemanager.view_casereview")) {
      detailComps.push({
        tabTitle: "Injuries",
        order: 5,
        component: <Injuries caseObj={caseObj} />,
      });
    }

    return detailComps.sort((a, b) => a.order - b.order);
  };

  const DetailTabs = generateDetailTabs();

  const activeTabIndex = tabTitle
    ? DetailTabs.findIndex((tab) => tab?.tabTitle === tabTitle)
    : 0;

  const handleChange = (_event, newValue) => {
    setTabTitle(DetailTabs[newValue]?.tabTitle ?? null);
  };

  useEffect(() => {
    return () => {
      setTabTitle("Client Info");
      setLawFirmFollowUps([]);
      setClientFollowUps([]);
      setProviderFollowUps([]);
    };
  }, [
    setClientFollowUps,
    setIsFollowUpsExpanded,
    setLawFirmFollowUps,
    setProviderFollowUps,
    setTabTitle,
  ]);

  if (params.id !== "null") {
    return (
      <ClientDetailProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <p className="text-[12px] text-gray-700 text-normal text-right mr-2">
            {!caseObj || userType !== "Client Case Management" ? (
              ""
            ) : (
              <>
                <Tooltip title={"Case Last Updated"}>
                  <History />
                </Tooltip>
                &nbsp;
                <span>
                  {new Date(caseObj.updated_at).toLocaleString("en-US", {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                  &nbsp;(
                  {formatDistance(new Date(caseObj.updated_at), new Date())})
                </span>
              </>
            )}
          </p>
          <div className="w-full h-full overflow-y-scroll mb-4 pb-6 flex lg:flex-row flex-col bg-white">
            <div
              className={`pl-2 ${
                userRoles.permissions.includes("casemanager.view_casenotes")
                  ? "flex-1"
                  : "w-full"
              }`}
            >
              <div className="flex items-center xl:flex-row flex-col relative">
                <div className="xl:w-1/3 w-full">
                  <ClientSearchMinimized />
                </div>
                <div className="xl:w-2/3 w-full">
                  <div>
                    {" "}
                    <div className="flex items-center justify-center">
                      <div className="mr-2">
                        {!caseObj ? (
                          ""
                        ) : caseObj.entity ? (
                          <div className="text-center font-bold flex items-center justify-center text-[14px]">
                            <Tooltip title="Entity">
                              <SupervisedUserCircle className="text-[18px]" />
                            </Tooltip>
                            <p>
                              {!caseObj
                                ? ""
                                : !caseObj.entity
                                ? ""
                                : caseObj.entity.name}{" "}
                              -
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <IconButton
                          className="w-[20px] mr-2"
                          onClick={() => setOpenLfDetails(true)}
                          disabled={
                            userRoles.permissions.includes(
                              "lawyer.view_all_law_firms"
                            ) &&
                            caseObj &&
                            caseObj.law_firm
                              ? false
                              : true
                          }
                        >
                          {" "}
                          <Tooltip title="Open Law Firm Details">
                            <AccountBalanceIcon className="text-black" />
                          </Tooltip>
                        </IconButton>
                        {!caseObj
                          ? ""
                          : !caseObj.law_firm_name
                          ? "No Law Firm Assigned"
                          : caseObj.law_firm_name}
                      </div>
                    </div>
                  </div>
                  {userRoles.permissions.includes(
                    "users.view_usertocaseassignments"
                  ) ? (
                    <CaseAssignment
                      currentClient={!caseObj ? "" : caseObj.pk}
                      caseObj={caseObj}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <StaticBar
                caseId={params.id}
                setViewDocuments={setViewDocuments}
                setUploadDocument={setUploadDocument}
                docNeedsType={docNeedsType}
                setOpenExpense={setOpenExpense}
              />
              <LiensBalancesModal
                openExpense={openExpense}
                setOpenExpense={setOpenExpense}
              />
              <div>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    padding: "0",
                  }}
                >
                  <Tabs
                    value={activeTabIndex}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="justify-between "
                    variant="fullWidth"
                    TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
                    sx={{
                      "& button.Mui-selected": {
                        color: `#9333EA`,
                      },
                      ".Mui-selected": {
                        color: `#9333EA`,
                      },
                      ".MuiBox-root.div": {
                        padding: 0,
                      },
                    }}
                  >
                    {DetailTabs.map((tab, idx) => (
                      <Tab
                        key={idx}
                        label={tab.tabTitle}
                        {...a11yProps(idx)}
                        className={
                          DetailTabs.length <= 1
                            ? "w-full"
                            : `w-1/${DetailTabs.length}`
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
                {DetailTabs.map((tab, idx) => (
                  <TabPanel value={activeTabIndex} index={idx} key={idx}>
                    <div className="mb-4 rounded-md  shadow-lg mt-5">
                      {tab.component}
                    </div>
                  </TabPanel>
                ))}
              </div>
            </div>

            <div className="lg:w-[30%] w-full mt-3">
              {userRoles.permissions.includes(
                "casemanager.can_view_follow_ups"
              ) &&
                userRoles.permissions.includes(
                  "casemanager.view_actionableitems"
                ) && (
                  <CaseFollowUps
                    clientId={caseObj?.client?.pk}
                    caseId={!caseObj ? "" : caseObj.pk}
                    lawFirmId={caseObj?.law_firm}
                  />
                )}
              {userRoles.permissions.includes("casemanager.view_casenotes") ? (
                <div className="mx-2 w-full">
                  <PermissionWrapper
                    permission={
                      !userRoles.permissions.includes("call_log.view_calllog")
                    }
                  >
                    <ClientNotes
                      caseObj={!caseObj ? "" : caseObj}
                      clientLawfirm={clientLawfirm}
                      params={params}
                    />
                  </PermissionWrapper>
                  <PermissionWrapper
                    permission={userRoles.permissions.includes(
                      "call_log.view_calllog"
                    )}
                  >
                    <TabClientNotesCallLog
                      caseObj={caseObj}
                      clientLawfirm={clientLawfirm}
                      params={params}
                    />
                  </PermissionWrapper>
                </div>
              ) : (
                ""
              )}
            </div>
            <ResponseModal
              title="Case Info"
              description="Case Information Updated"
              openBool={openSuccess}
              setOpenBool={setOpenSuccess}
            />
            <Dialog open={openLfDetails} fullWidth>
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    fontSize: "20px",
                  }}
                >
                  <Button onClick={() => setOpenLfDetails(false)}>
                    <CloseOutlined />
                  </Button>
                </div>
                <h1
                  style={{
                    fontSize: "24px",
                    margin: "0 0 1rem 0",
                    textAlign: "left",
                    fontWeight: "bold",
                    color: "#1976d2",
                  }}
                >
                  Law Firm Detail
                </h1>
                {!clientLawfirm ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <div
                    className="flex rounded-b-lg"
                    style={{ padding: "0 0 2rem 0" }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <h3 className="my-2">
                        <strong>Name:</strong> {clientLawfirm.name}
                      </h3>
                      <h3 className="my-2">
                        <strong>Address:</strong>{" "}
                        {!clientLawfirm.address
                          ? ""
                          : clientLawfirm.address.raw}
                      </h3>
                      <h3 className="my-2">
                        <strong>Email:</strong>{" "}
                        <a
                          href={`mailto:${clientLawfirm.email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {clientLawfirm.email}
                        </a>
                      </h3>
                      <h3 className="my-2">
                        <strong>Lawfirm Website:</strong>{" "}
                        <a
                          href={clientLawfirm.homepage_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {clientLawfirm.homepage_url}
                        </a>
                      </h3>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <h3>
                          <strong>Phone:</strong> {clientLawfirm.phone}&nbsp;
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Dialog>
            <DocumentUpload
              closeUploadModal={closeUploadModal}
              casePk={!caseObj ? "" : caseObj.pk}
              clientId={caseObj?.client?.pk}
              uploadDocument={uploadDocument}
              setUploadDocument={setUploadDocument}
              activeProvObj={documentUploadProvOptions}
              docTrigger={docTrigger}
              setDocTrigger={setDocTrigger}
              setViewDocuments={setViewDocuments}
              docNeedsType={docNeedsType}
              caseInfo={caseObj}
            />
            <ViewDocuments
              documents={clientDocs}
              viewDocuments={viewDocuments}
              setViewDocuments={setViewDocuments}
              documentTypeObj={documentTypeObj}
              truncate={truncate}
              docTrigger={docTrigger}
              setDocTrigger={setDocTrigger}
              docResLimit={docResLimit}
              setDocResLimit={setDocResLimit}
              showMoreDocsVisible={showMoreDocsVisible}
              setShowMoreDocsVisible={setShowMoreDocsVisible}
              searchDocs={searchDocs}
              setSearchDocs={setSearchDocs}
              docLoading={docLoading}
              docNeedsType={docNeedsType}
              clientId={caseObj?.client?.pk}
              activeProvObj={documentUploadProvOptions}
            />
            <Dialog open={viewImage}>
              <div style={{ padding: "2rem" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 0 1rem",
                  }}
                >
                  <IconButton onClick={() => closeImageModal()}>
                    <CloseOutlined />
                  </IconButton>
                </div>
                <Tooltip title="Download Image">
                  <a
                    download={activeImage.desc}
                    href={activeImage.image}
                    title={activeImage.desc}
                    target="_blank44"
                  >
                    <img alt={activeImage.desc} src={activeImage.image} />
                  </a>
                </Tooltip>
              </div>
            </Dialog>
            <Modal
              open={openCbModal}
              onClose={() => setOpenCbModal(false)}
              title={"Schedule Callback"}
              actions={[
                {
                  label: "Submit",
                  onClick: () => addCallback(""),
                },
                {
                  label: "Clear",
                  onClick: () => addCallback("reset"),
                  variant: "outlined",
                },
              ]}
            >
              <div className="flex flex-col gap-4">
                <FormField label="Callback Date/Time">
                  <DesktopDateTimePicker
                    value={!callback ? new Date() : new Date(callback)}
                    onChange={(newValue) => setCallback(newValue)}
                    sx={{
                      ...inputStyles,
                      "& input": {
                        marginLeft: "-8px",
                      },
                    }}
                    components={{
                      OpenPickerIcon: CalendarMonth,
                    }}
                    slotProps={{
                      inputAdornment: {
                        position: "start",
                      },
                    }}
                  />
                </FormField>
                <FormField label="Change Callback Reason/Description">
                  <TextField
                    sx={inputStyles}
                    onChange={(e) => setCallBackReason(e.target.value)}
                    value={callBackReason}
                  />
                </FormField>
              </div>
            </Modal>
          </div>
          <ResponseModal
            title="Success"
            description="The case content was updated"
            openBool={openSuccess}
            setOpenBool={setOpenSuccess}
          />
          <ResponseModal
            title="Case update failed"
            isError={true}
            openBool={openError}
            setOpenBool={setOpenError}
            errorMessage={errorArray}
          />
        </LocalizationProvider>
      </ClientDetailProvider>
    );
  } else {
    return (
      <div className="h-1/2 w-full text-center rounded-lg text-2xl mt-20 hover:bg-gray-200">
        <br />
        Please Select a Client to View Client's Details...
      </div>
    );
  }
}
