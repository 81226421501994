export const ENTITY_TYPE_PROVIDER = "Provider";
export const ENTITY_TYPE_LAW_FIRM = "Law Firm";
export const ENTITY_TYPE_CCM = "Client Case Management";
export const PRIMARY_CLIENT_MANAGER = "primaryclientmanager";
export const IS_EXPAND_FOLLOW_UP = "is_expand_follow_up";
export const DOCUMENT_TYPES = {
  DRIVER_LICENSE_FRONT: 11,
  DRIVER_LICENSE_BACK: 25
};
export const APPT_STATUS = {
  Done: "Complete",
  Overdue: "Missed",
  Assigned: "Scheduled"
};
