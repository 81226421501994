import React, { useContext, useEffect, useState, useCallback } from "react";
import { Info, UploadFile } from "@mui/icons-material";
import { Button, Autocomplete, TextField, Tooltip } from "@mui/material";
import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";
import ResponseModal from "../../../../../../global/ResponseModal";
import DocSourceField from "./Fields/DocSourceField";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import CaseSummaryContext from "../../CaseSummaryV2/context/CaseSummaryContext";
import LoadingIndicator from "../../../../../../global/LoadingIndicator";
import { ClientDetailContext } from "../../context/ClientDetailContext";
import AssociateAppointments from "./ViewDocuments/Modals/AssociateAppointments";
import SelectBasic from "../../../../../../global/FormComponents/SelectBasic";
import { document } from "../../../../../../api/endpoints/document";

const FileUpload = ({
  closeUploadModal,
  casePk,
  clientId,
  docTrigger,
  setDocTrigger,
  setViewDocuments,
  activeProvObj,
  setOpenAssociateAppointmentsModal,
}) => {
  const { accessToken, loggedInUser } = useContext(DataContext);
  const {
    documentTrigger,
    setDocumentTrigger,
    caseSummaryTrigger,
    setCaseSummaryTrigger,
  } = useContext(CaseSummaryContext);
  const {
    selectedAppointment,
    setSelectedAppointment,
    isLaunchedFromAppointmentsTab,
  } = useContext(ClientDetailContext);

  const [file, setFile] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [providerPk, setProviderPk] = useState("");
  const [referralToPk, setReferralToPk] = useState("");
  const [referralFromPk, setReferralFromPk] = useState("");
  const [fileReq, setFileReq] = useState("");
  const [docValidation, setDocValidation] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [docSourceDict, setDocSourceDict] = useState(null);

  const [docSource, setDocSource] = useState(
    isLaunchedFromAppointmentsTab ? 3 : ""
  );
  const [uploadedDocument, setUploadedDocument] = useState({});

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const createFieldOptions = useCallback(
    (data) => {
      let newArr = [];
      for (let i = 0; i < data.length; i++) {
        if (Object.keys(selectedAppointment).length > 0) {
          if (data[i].additional_attributes.isAppointmentDoc) {
            newArr.push({ id: data[i].key, label: data[i].label });
          }
          if (data[i].additional_attributes.isDefault) {
            setDocumentType({ id: data[i].key, label: data[i].label });
          }
          setProviderPk({id:selectedAppointment.provider.pk, label:selectedAppointment.provider.name});
        } else {
          newArr.push({ id: data[i].key, label: data[i].label });
        }
        
      }
      return newArr;
    },
    [selectedAppointment]
  );

  const fetchDocTypes = useCallback(async (fieldOptionsUrls) => {
    return Promise.all(fieldOptionsUrls.map(async (url) => await url))
      .then((res) => res)
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorArray(errArr);
        setOpenError(true);
      });
  }, []);

  useEffect(() => {
    const fieldOptionsUrls = [
      document.getDocumentTypes(accessToken),
      document.getDocumentTypesValidation(accessToken),
      document.getSourceFieldOptions(accessToken),
    ];

    fetchDocTypes(fieldOptionsUrls)
      .then(async (results) => {
        if (results) {
          if (results[0]) {
            setDocTypeOptions(createFieldOptions(results[0].results));
          }
          if (results[1]) {
            setDocValidation(results[1]);
          }
          if (results[2]) {
            setDocSourceDict(results[2].results);
          }
        }
        return results;
      })
      .then(async (results) => {
        return results;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setErrorArray(errArr);
        setOpenError(true);
      });
  }, [accessToken, fetchDocTypes, createFieldOptions]);

  const handleFileUpload = (event) => {
    // get the selected file from the input
    event.preventDefault();
    setIsLoading(true);
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file);
    formData.append("case", casePk);
    formData.append("document_type", documentType?.id);
    if (
      providerPk.id &&
      docValidation.required.provider.includes(documentType?.id)
    ) {
      formData.append("provider", providerPk.id);
    }
    if (
      referralToPk.id &&
      docValidation.required.referral_to.includes(documentType?.id)
    ) {
      formData.append("referral_to", referralToPk.id);
    }
    if (
      referralFromPk.id &&
      docValidation.required.referral_from.includes(documentType?.id)
    ) {
      formData.append("referral_from", referralFromPk.id);
    }
    if (docSource) {
      formData.append("source", docSource);
    }
    axios
      .post(`/api/documents/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((res) => {
        // handle the response
        setUploadSuccess(true);
        setIsLoading(false);
        if (isLaunchedFromAppointmentsTab) {
          setUploadedDocument(res.data);
        } else {
          if (+documentType?.id === 7) {
            setDocumentTrigger(!documentTrigger);
            setCaseSummaryTrigger(!caseSummaryTrigger);
          }
        }

        return res;
      })
      .catch((error) => {
        // handle errors
        setUploadFailed(true);
        setIsLoading(false);
        let errArr = ApiRequestErrorHandler(error.response.data);
        setUploadErrorMessage(errArr);
        setErrorStatus(error.response.status);
        return error;
      });
  };

  const handleUploadSuccess = () => {
    setUploadSuccess(false);
    if (!isLaunchedFromAppointmentsTab) {
      closeUploadModal();
    } 
    setDocTrigger(!docTrigger);
  };
  const handleNeedsTypeFail = () => {
    if (errorStatus === 403) {
      closeUploadModal();
      setViewDocuments(true);
    } else {
      setUploadFailed(false);
    }
  };

  const handleDocumentType = (e, optObj) => {
    if (!optObj) {
      setFileReq("");
      setDocumentType();
      return;
    } else {
      setFileReq(docValidation.valid_extensions[optObj.id]);
      setDocumentType(optObj);
      if (providerPk && !docValidation.required.provider.includes(optObj.id)) {
       setProviderPk("");
      }
      if (
        referralToPk &&
        !docValidation.required.referral_to.includes(optObj.id)
      ) {
        setReferralToPk("");
      }
      if (
        referralToPk &&
        !docValidation.required.referral_from.includes(optObj.id)
      ) {
        setReferralFromPk("");
      }
    }
  };

  return (
    <div className="flex">
      <div className="grow mx-auto my-1">
        {isLaunchedFromAppointmentsTab &&
          Object.keys(uploadedDocument).length > 0 && (
            <div>
              <h3 className="font-bold">Your file has been uploaded.</h3>To
              associate an appointment to this document please select an
              appointment.
              <hr className="my-4" />
            </div>
          )}

        {(!isLaunchedFromAppointmentsTab ||
          (isLaunchedFromAppointmentsTab &&
            Object.keys(uploadedDocument).length === 0)) && (
          <form onSubmit={handleFileUpload} className="w-full">
            <div>
              <p className="text-[12px] text-slate-500 text-center">
                <Info className="text-[12px]" />
                &nbsp; Max Size: 100MB &nbsp;|&nbsp; Supported File Extensions -{" "}
                {!fileReq ? "Select a Document Type to View" : fileReq}
              </p>
            </div>
            <div className="w-full my-4 mx-auto">
              <Autocomplete
                disablePortal
                options={docTypeOptions}
                value={documentType}
                noOptionsText="No Results"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                className="w-full"
                renderInput={(params) => (
                  <TextField {...params} label="Document Types" />
                )}
                onChange={(e, value) => {
                  handleDocumentType(e, value);
                }}
              />
            </div>
            <div className="my-3 mx-auto">
              <DocSourceField
                isFieldRequired={docValidation?.required?.source?.includes(
                  documentType?.id
                )}
                docSource={docSource}
                setDocSource={setDocSource}
              />
            </div>

            {!isLaunchedFromAppointmentsTab &&
              Object.keys(uploadedDocument).length === 0 &&
              (docValidation || documentType) &&
              docValidation.required.provider.includes(documentType?.id) &&
              activeProvObj && (
                <SelectBasic
                  selectedVal={providerPk}
                  setSelectedVal={setProviderPk}
                  placeholder={"Related Provider"}
                  title={"Related Provider"}
                  optionsData={activeProvObj}
                />
              )}
            {(docValidation || documentType) && (
              <div>
                {(docValidation?.required?.referral_to.includes(
                  documentType?.id
                ) ||
                  docValidation?.required?.referral_from.includes(
                    documentType?.id
                  )) && (
                  <div className="mx-4 mt-1 mb-10 p-4 text-black rounded-sm bg-yellow-100 text-sm">
                    <strong>NOTE: </strong>Only providers that are already
                    assigned to the client's case can be chosen below. If the
                    provider needed is not shown below, please add them to the
                    case before uploading the referral file.
                  </div>
                )}
                {docValidation.required.referral_to.includes(
                  documentType?.id
                ) &&
                  activeProvObj && (
                    <SelectBasic
                      selectedVal={referralToPk}
                      setSelectedVal={setReferralToPk}
                      placeholder={"Referred To"}
                      title={"Referred To"}
                      optionsData={activeProvObj}
                    />
                  )}
                {docValidation.required.referral_from.includes(
                  documentType?.id
                ) &&
                  activeProvObj && (
                    <SelectBasic
                      selectedVal={referralFromPk}
                      setSelectedVal={setReferralFromPk}
                      placeholder={"Referred From"}
                      title={"Referred From"}
                      optionsData={activeProvObj}
                    />
                  )}
              </div>
            )}

            <>
              <div className="my-1 mx-auto flex fit-content border-solid-1">
                <Tooltip
                  title={file ? "Click to replace Document" : "Select Document"}
                >
                  <Button
                    component="label"
                    className="flex space-between w-full"
                    startIcon={<UploadFile />}
                  >
                    {!file ? (
                      <span>Select Document</span>
                    ) : (
                      <span>{file.name}</span>
                    )}
                    <input type="file" hidden onChange={handleFileSelect} />
                  </Button>
                </Tooltip>
              </div>
              <div className="mx-auto my-3 text-center">
                <Button type="submit" variant="outlined">
                  Upload Document
                </Button>
              </div>
            </>
          </form>
        )}
        {isLaunchedFromAppointmentsTab && (
          <div className="my-5">
            <span className="font-bold">Provider:</span>{" "}
            {selectedAppointment.provider.name}
          </div>
        )}
        {isLaunchedFromAppointmentsTab &&
          Object.keys(uploadedDocument).length > 0 && (
            <div>
              <div>
                <span className="font-bold">Document Type:</span>{" "}
                {documentType.label}
              </div>
              {docSourceDict && (
                <div>
                  <span className="font-bold">Source:</span>{" "}
                  {docSourceDict[docSource].label}
                </div>
              )}
              <div>
                <span className="font-bold">File:</span>{" "}
                {uploadedDocument.file_name}
              </div>
              {docValidation.required.referral_from.includes(
                documentType?.id
              ) && (
                <div>
                  <span className="font-bold">Referral To:</span>{" "}
                  {uploadedDocument.referral_from}
                </div>
              )}
              {docValidation.required.referral_to.includes(
                documentType?.id
              ) && (
                <div>
                  <span className="font-bold">Referral To:</span>{" "}
                  {uploadedDocument.referral_to}
                </div>
              )}
            </div>
          )}
      </div>
      {isLaunchedFromAppointmentsTab &&
        Object.keys(uploadedDocument).length > 0 && (
          <div className="shrink basis-1/2">
            <AssociateAppointments
              document={
                isLaunchedFromAppointmentsTab ? uploadedDocument : document
              }
              clientId={clientId}
              closeUploadModal={closeUploadModal}
              docTrigger={docTrigger}
              setDocTrigger={setDocTrigger}
            />
          </div>
        )}

      {isLoading && <LoadingIndicator isLoading={true} />}

      <ResponseModal
        title="Upload Success"
        description="The image/document was successfully uploaded"
        openBool={uploadSuccess}
        setOpenBool={setUploadSuccess}
        handleCloseFunc={handleUploadSuccess}
      />
      <ResponseModal
        title="Upload failed"
        isError={true}
        openBool={uploadFailed}
        setOpenBool={setUploadFailed}
        errorMessage={uploadErrorMessage}
        handleCloseFunc={handleNeedsTypeFail}
      />
    </div>
  );
};
export default FileUpload;
