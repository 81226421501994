/* Shared state for the Client Detail Page. This will reduce usage of DataContext and allows for garbage collection of data when not on Client Detail Page and data is no longer needed. */

import { createContext, useState } from "react";

export const ClientDetailContext = createContext();

const ClientDetailProvider = ({ children }) => {
  const [caseAppointments, setCaseAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [isLaunchedFromAppointmentsTab, setIsLaunchedFromAppointmentsTab] =
    useState(false);
  const [reloadClientDetail, setReloadClientDetail] = useState(false);
  const [defaultSelectedDocumentType, setDefaultSelectedDocumentType] = useState("");
  

  return (
    <ClientDetailContext.Provider
      value={{
        caseAppointments,
        setCaseAppointments,
        selectedAppointment,
        setSelectedAppointment,
        isLaunchedFromAppointmentsTab,
        setIsLaunchedFromAppointmentsTab,
        reloadClientDetail,
        setReloadClientDetail,
        defaultSelectedDocumentType,
        setDefaultSelectedDocumentType
      }}
    >
      {children}
    </ClientDetailContext.Provider>
  );
};

export default ClientDetailProvider;
