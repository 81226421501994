import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import qs from "query-string";
import CssBaseline from "@mui/material/CssBaseline";
import { CircularProgress, Button, Drawer } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

import { ProviderList } from "./ProviderList";
import { PMStateContext } from "./context/PMStateContext";
import { PMDispatchContext } from "./context/PMDispatchContext";
import useApiData from "./hooks/useApiData";
import {
  searchText,
  setCurrentPageSet,
  setCurrentPageUrlSet,
} from "./state/actions";
import { initialState, pmReducer } from "./state/pmReducer";
import DataContext from "../../../../../context/DataContext";
import PermissionWrapper from "../../../../global/PermissionWrapper";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import SearchAutoSubmit from "../../../../global/SearchAutoSubmit";
import PaginationControls from "../../../../global/PaginationControls";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import AddProviderForm from "./AddProviderForm";

const DEFAULT_ORDERING_VAL = "name";

const ProviderManager = () => {
  const { loggedInUser, accessToken, userRoles } = useContext(DataContext);
  const [state, dispatch] = useReducer(pmReducer, initialState);
  const { data, isLoading, getData, resultCount } = useApiData();

  const [canViewProviders, setCanViewProviders] = useState(true);
  const [orderingVal, setOrderingVal] = useState(DEFAULT_ORDERING_VAL);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newProviderCreated, setNewProviderCreated] = useState(false);

  useEffect(() => {
    setCanViewProviders(
      userRoles.permissions.includes("provider.change_provider")
    );
  }, [userRoles?.permissions]);

  const getProviders = useCallback(() => {
    let query = qs.stringify(
      {
        managed_by: loggedInUser?.pk,
        ordering: orderingVal,
        search: !state.search ? undefined : state.search,
      },
      { skipNull: true }
    );

    getData(
      `api/provider?${state.paginationUrl || ""}${query || ""}`,
      accessToken
    );
  }, [
    state.paginationUrl,
    accessToken,
    loggedInUser?.pk,
    orderingVal,
    state.search,
    getData,
  ]);

  useDebouncedEffect(
    getProviders,
    [state.search, state.currentPage, orderingVal, loggedInUser?.pk],
    800
  );

  useEffect(() => {
    if (newProviderCreated) {
      getProviders();
    }
  }, [getProviders, newProviderCreated]);

  const handleSearchOnChanged = (text) => {
    searchText(dispatch, text);
    setCurrentPageUrlSet(dispatch, "");
    setCurrentPageSet(dispatch, 1);
  };

  const handlePageChanged = (_, value) => {
    setCurrentPageSet(dispatch, value);

    if (value === 1) {
      setCurrentPageUrlSet(dispatch, `limit=${state.resLimit}&`);
    }
    setCurrentPageUrlSet(
      dispatch,
      `limit=${state.resLimit}&offset=${state.resLimit * (value - 1)}&`
    );
  };

  const { width } = useWindowDimensions();

  const canAddProvider = userRoles.permissions.includes(
    "provider.add_provider"
  );

  const handleOpenAddProviderForm = () => {
    setNewProviderCreated(false);
    setIsDrawerOpen(true);
  };

  return (
    <div
      data-testid="provider-manager"
      className="bg-white w-full overflow-hidden"
    >
      <PermissionWrapper permission={canViewProviders}>
        <PMStateContext.Provider value={state}>
          <PMDispatchContext.Provider value={dispatch}>
            <CssBaseline />
            <div className="p-6" data-testid="provider-manager-main">
              <div className="my-2">
                <h1 className="text-[1.8rem] font-medium">Manage Providers</h1>
                <div className="flex justify-between mb-4">
                  <SearchAutoSubmit
                    placeholder="Search by Provider Name, Address, City, State."
                    onchange={handleSearchOnChanged}
                  />
                  {canAddProvider && (
                    <Button
                      variant="contained"
                      onClick={handleOpenAddProviderForm}
                      className="w-[200px] min-w-[200px] h-[40px] mt-auto"
                    >
                      <AddCircle className="mr-2" />
                      Add Provider
                    </Button>
                  )}
                </div>
                <Drawer
                  sx={{
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: width > 765 ? "50%" : "100%",
                      boxSizing: "border-box",
                    },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={isDrawerOpen}
                >
                  <AddProviderForm
                    onClose={() => setIsDrawerOpen(false)}
                    setNewProviderCreated={setNewProviderCreated}
                  />
                </Drawer>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="w-full">
                  {isLoading ? (
                    <div className="flex justify-center my-24">
                      <CircularProgress color="secondary" />
                    </div>
                  ) : (
                    <div data-testid="provider-list">
                      <ProviderList
                        data={data?.results}
                        orderingVal={orderingVal}
                        setOrderingVal={setOrderingVal}
                      />
                    </div>
                  )}
                </div>
                <div className="w-fit mx-auto mt-auto">
                  <PaginationControls
                    resultCount={resultCount}
                    limit={state.resLimit}
                    handleChange={handlePageChanged}
                    currentPage={state.currentPage}
                  />
                </div>
              </div>
            </div>
          </PMDispatchContext.Provider>
        </PMStateContext.Provider>
      </PermissionWrapper>
      <PermissionWrapper permission={!canViewProviders}>
        <div data-testid="no-access-message">
          You do not have permission to access.
        </div>
      </PermissionWrapper>
    </div>
  );
};

export default ProviderManager;
